// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1hSsKZ4RMBsyu6L9Iq_ERk {\n    --maskX: 0;\n    --maskY: 0;\n    position: relative;\n    cursor: auto;\n    user-select:none;\n}\n\n._19dVKKGMaGIRreIWuF6W3O {\n    color: #000000;\n    font-weight: 600;\n    font-size: 30px;\n    padding: 8px;\n    font-family: 'Playfair Display', serif;\n    line-height: 1.5;\n    text-shadow: 5px 3px 8px rgba(0, 0, 0, 0.2);\n    margin: 0;\n    cursor: pointer;\n}\n  \n._3Q5rS065BuaPphR0uuke0W {\n    position: absolute;\n    left: 0;\n    top: 0;\n    color: #ffffff;\n    transition: all 0.4s ease-out;\n    clip-path: polygon(\n        0 0,\n        calc(var(--maskX) * 1% + (var(--maskY) - 50) * 0.4%) 0,\n        calc(var(--maskX) * 1% + (var(--maskY) - 50) * -0.4%) 100%,\n        0 100%\n    );\n}\n  ", ""]);
// Exports
exports.locals = {
	"animatedTextContainer": "_1hSsKZ4RMBsyu6L9Iq_ERk",
	"animatedTextContent": "_19dVKKGMaGIRreIWuF6W3O",
	"animatedTextContentClone": "_3Q5rS065BuaPphR0uuke0W _19dVKKGMaGIRreIWuF6W3O"
};
module.exports = exports;
